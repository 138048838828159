import React from 'react';
import { graphql } from 'gatsby';
import { GatsbyImage, getImage } from 'gatsby-plugin-image';
import Layout from '../components/Layout';
import BreadcrumbSchema from '../components/BreadcrumbSchema';
import Breadcrumbs from '../components/Breadcrumbs';
import { Helmet } from 'react-helmet';
import { FaUser, FaClock, FaTags } from 'react-icons/fa';

const BlogPage = ({ data }) => {
  const posts = data.allContentfulBlogPage.edges.map(({ node }) => node);
  const title = 'ブログ一覧｜奈良のホームページ制作なら｜インヴォルブ';
  const description =
    '当事務所のメンバーが、ウェブ業界やWebマーケティングの最新動向、気付き、考え方 を発信しています。業界のトレンドや実践的なノウハウを交えながら、より良いWebのあり方について考察していきます。ぜひご覧ください。';
  const breadcrumbList = [
    { name: 'ホーム', url: 'https://www.involve-in.jp/' },
    {
      name: 'ブログ一覧',
      url: 'https://www.involve-in.jp/blog',
    },
  ];

  return (
    <Layout>
      <Helmet>
        <BreadcrumbSchema breadcrumbList={breadcrumbList} />
        <title>{title}</title>
        <meta name="description" content={description} />
        <meta name="robots" content="index, follow" />
        <link rel="canonical" href="https://www.involve-in.jp/blog/" />
        <link
          rel="icon"
          type="image/x-icon"
          href="/images/common/favicon.ico"
        />
        <meta property="og:title" content={title} />
        <meta property="og:description" content={description} />
        <meta property="og:type" content="website" />
        <meta property="og:url" content="https://www.involve-in.jp/blog" />
        <meta
          property="og:image"
          content="https://www.involve-in.jp/images/common/ogp.png"
        />
        <meta name="twitter:card" content="summary_large_image" />
        <meta name="twitter:title" content={title} />
        <meta name="twitter:description" content={description} />
        <meta
          name="twitter:image"
          content="https://www.involve-in.jp/images/common/ogp.png"
        />
      </Helmet>
      <section className="max-w-screen-2xl w-full mx-auto py-32 px-6 bg-white bg-opacity-95 shadow-lg rounded-lg">
        <div className="container mx-auto max-w-screen-2xl w-full px-6">
          <h1 className="font-roboto text-6xl md:text-[10rem] font-bold text-center mb-12">
            Blog
          </h1>
          <h2 className="text-3xl font-bold mb-6 text-center">ブログ</h2>
          {/* 記事リスト */}
          <div className="grid grid-cols-1 md:grid-cols-2 gap-16">
            {posts.map((post) => {
              const image = getImage(post.image?.gatsbyImageData);
              return (
                <div
                  key={post.id}
                  className="flex flex-col md:flex-row bg-white bg-opacity-80 rounded-3xl p-10 shadow-lg transition transform hover:shadow-2xl"
                >
                  {/* 画像 */}
                  {image && (
                    <div className="w-full md:w-1/3 aspect-[4/3] overflow-hidden rounded-xl">
                      <GatsbyImage
                        image={image}
                        alt={post.title}
                        className="w-full h-auto object-contain"
                      />
                    </div>
                  )}

                  {/* テキストエリア */}
                  <div className="w-full md:w-2/3 md:pl-10 flex flex-col justify-between mt-6 md:mt-0">
                    <a
                      href={`/blog/${post.slug}`}
                      className="text-4xl font-bold text-gray-900 hover:underline"
                    >
                      {post.title}
                    </a>

                    {post.blogTags?.length > 0 && (
                      <div className="flex flex-wrap gap-4 mt-4">
                        {post.blogTags.map((tag) => (
                          <span
                            key={tag.name}
                            className="border-2 border-black text-black text-xl font-medium px-5 py-2 rounded-full flex items-center gap-2"
                          >
                            <FaTags className="text-gray-500 text-lg" />
                            {tag.name}
                          </span>
                        ))}
                      </div>
                    )}

                    <div className="flex flex-col md:flex-row items-start md:items-center text-gray-700 text-lg mt-4 gap-3 md:gap-6">
                      <div className="flex items-center gap-2">
                        <FaClock className="text-gray-500 text-2xl" />
                        <span>更新日: {post.updatedAt}</span>
                      </div>
                      <div className="flex items-center gap-2">
                        <FaUser className="text-gray-500 text-2xl" />
                        <span>{post.author?.name || '不明'}</span>
                      </div>
                    </div>
                  </div>
                </div>
              );
            })}
          </div>
        </div>
      </section>
      <Breadcrumbs breadcrumbList={breadcrumbList} />
    </Layout>
  );
};

// ✅ GraphQL クエリ修正
export const query = graphql`
  query {
    allContentfulBlogPage(sort: { publishDate: DESC }) {
      edges {
        node {
          id
          title
          slug
          publishDate(formatString: "YYYY.MM.DD")
          updatedAt(formatString: "YYYY.MM.DD")
          image {
            gatsbyImageData(
              layout: CONSTRAINED
              width: 600
              placeholder: BLURRED
            )
          }
          blogTags {
            name
          }
          author {
            name
          }
        }
      }
    }
  }
`;

export default BlogPage;
